<template>
  <div>
    <p class="mb-3">
      Select the correct expression for the equilibrium constant for the reaction below:
    </p>

    <p class="mb-0 pl-14">
      <stemble-latex
        :content="`$\\ce{${equivAint}A(aq) + ${equivBint}B(aq) <=> ${equivCint}C(aq) + ${equivDint}D(aq)}$`"
      />
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="mb-3 mt-3"
          :value="option.value"
        >
          <template #label>
            <latex-number style="font-size: 14px" :number="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '../displayers/LatexNumber';

export default {
  name: 'Question170a',
  components: {
    StembleLatex,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    equivA() {
      return this.taskState.getValueBySymbol('equivA').content;
    },
    equivB() {
      return this.taskState.getValueBySymbol('equivB').content;
    },
    equivC() {
      return this.taskState.getValueBySymbol('equivC').content;
    },
    equivD() {
      return this.taskState.getValueBySymbol('equivD').content;
    },
    equivAint() {
      if (this.equivA.toInt() === 1) {
        return '';
      } else {
        return this.equivA.toInt();
      }
    },
    equivBint() {
      return this.equivB.toInt();
    },
    equivCint() {
      return this.equivC.toInt();
    },
    equivDint() {
      if (this.equivD.toInt() === 1) {
        return '';
      } else {
        return this.equivD.toInt();
      }
    },
    options1() {
      return [
        {
          expression:
            '\\text{K}=\\cfrac{[\\text{A}]^' +
            this.equivAint +
            '[\\text{B}]^{' +
            this.equivBint +
            '}}{[\\text{C}]^{' +
            this.equivCint +
            '}[\\text{D}]^{' +
            this.equivDint +
            '}}',
          value: 'RoverP',
        },
        {
          expression: '\\text{K}=\\cfrac{\\text{[C][D]}}{\\text{[A][B]}}',
          value: 'PoverRnoStoich',
        },
        {
          expression: '\\text{K}=\\cfrac{\\text{[A][B]}}{\\text{[C][D]}}',
          value: 'RoverPnoStoich',
        },
        {
          expression:
            '\\text{K}=\\cfrac{[\\text{C}]^' +
            this.equivCint +
            '[\\text{D}]^{' +
            this.equivDint +
            '}}{[\\text{A}]^{' +
            this.equivAint +
            '}[\\text{B}]^{' +
            this.equivBint +
            '}}',
          value: 'PoverR',
        },
      ];
    },
  },
};
</script>
